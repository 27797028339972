<!-- Toolbar -->
<div class="toolbar" role="banner">
  <a
    class="circle-link"
    title="MTB"
    href="https://www.mtb.com/"
    target="_blank"
    rel="noopener"
  >
    <img
      class="circle-link"
      width="40"
      alt="MandT Logo"
      src="https://pbs.twimg.com/profile_images/1229772435374985222/1zIW5Mv4_400x400.jpg"
    />
  </a>
  <span>Exclusions</span>
  <div class="spacer"></div>
  <a
    aria-label="MandT Bank on twitter"
    target="_blank"
    rel="noopener"
    href="https://twitter.com/MandT_Bank"
    title="Twitter"
  >
    <svg
      id="twitter-logo"
      height="24"
      data-name="Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
    >
      <rect width="400" height="400" fill="none" />
      <path
        d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
        fill="#fff"
      />
    </svg>
  </a>
</div>

<div class="content" role="main">
  <div class="card-container"></div>

  <svg
    id="clouds"
    alt="Gray Clouds Background"
    xmlns="http://www.w3.org/2000/svg"
    width="2611.084"
    height="485.677"
    viewBox="0 0 2611.084 485.677"
  >
    <path
      id="Path_39"
      data-name="Path 39"
      d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
      transform="translate(142.69 -634.312)"
      fill="#eee"
    />
  </svg>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form.value)">
  <h4>Step 1: Select the originating site:</h4>

  <mat-chip-list selectable name="site" ngModel>
    <mat-chip
      #c="matChip"
      *ngFor="let option of chipList"
      [value]="option"
      (click)="toggleSelect(c)"
    >
      {{ option }}
    </mat-chip>
  </mat-chip-list>

  <mat-form-field>
    <mat-label>Path</mat-label>
    <input
      type="text"
      name="path"
      matInput
      placeholder="path/to/check"
      ngModel
    />
  </mat-form-field>
  <button mat-raised-button color="primary">Well?</button>
</form>

<div *ngIf="hops.length > 0">
  <div *ngFor="let hop of hops">{{ hop.status }} {{ hop.target }}</div>
</div>

<router-outlet></router-outlet>
