import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChip } from '@angular/material/chips';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private http: HttpClient) {}
  chipList = [
    'mtb.com',
    'wilmingtontrust.com',
    'loanofficers.mtb.com',
    'campaigns.mtb.com',
  ];

  hops: any[] = [];
  site: string = '';

  fontStyleControl = new FormControl();
  fontStyle?: string;

  async onSubmit(data: any) {
    const toSubmit = data.path.split('/').join('*');
    const appCode = this.getAppCode(this.site);
    const url = `http://localhost:3000/${appCode}/${toSubmit}`;
    const response = this.http.get(url).subscribe((val) => {
      this.hops = val as any[];
    });
  }

  toggleSelect(chip: MatChip) {
    chip.toggleSelected();
    this.site = chip.value;
    this.hops = [];
  }

  getAppCode(input: string): string {
    switch (input) {
      case 'mtb.com':
        return 'mtb';
      case 'wilmingtontrust.com':
        return 'wtu';
      case 'loanofficers.mtb.com':
        return 'mlo';
      case 'campaigns.mtb.com':
        return 'mcp';
      default:
        return 'mtb';
    }
  }
}
